import { NextPage } from "next";

import { css } from "@emotion/react";
import useSWR from "swr";

import { Link } from "@/components/atoms/Link";
import { expertApi, MeJsonV2 } from "@/lib/driver/NewspicksExpertApi";
import { Flex } from "@/ui/layout/Flex";
import { Layout } from "@/ui/layout/Layout";
import { PageRoot } from "@/ui/layout/PageRoot";

const NotFoundPage: NextPage = () => {
  const { data: expert } = useSWR<MeJsonV2>("/v2/me", () => expertApi.findMeV2());

  return (
    <PageRoot title="ページが見つかりませんでした">
      <Layout expert={expert}>
        <Flex justifyContent="center">
          <Flex style={style} flexDirection="column" alignItems="start" gap={24}>
            <h1>ご指定のページが見つかりませんでした</h1>
            <div>
              <p>アクセスしようとしたページは削除、変更されたか、現在利用できない可能性があります。 </p>
              <p>
                お手数をおかけしますが、
                <Link href="/mypage" textDecoration="none">
                  トップページ
                </Link>
                からお探しください。
              </p>
            </div>
          </Flex>
        </Flex>
      </Layout>
    </PageRoot>
  );
};

export default NotFoundPage;

const style = css`
  padding-top: 40px;
  padding-bottom: 80px;
`;
